<template>
  <section>
    <div
      v-show="processing" 
      class="attendance-file-process-wrapper pt-2"
    >
      <div>
        <div class="doc" />
      </div>
    </div>

    <div v-show="finished || attendanceFileId">
      <Doc 
        @click="openModal()"
        class="icon" 
        v-b-tooltip.hover title="Histórico pronto para download"
      />
    </div>

    <DocumentModal 
      v-if="url" 
      :url="url" 
      :documentName="documentName"
    />

    <ImportXmlTissErrorModal 
      :resume="modal_xml_tiss_error"
    />

    <ImportXmlTissSuccessModal 
      :batches="modal_xml_tiss"
    />
  </section>
  
</template>
<script>
import { mapGetters } from 'vuex';
import DocumentModal from './DocumentModal.vue'
import ImportXmlTissErrorModal from './ImportXmlTissErrorModal.vue'
import ImportXmlTissSuccessModal from './ImportXmlTissSuccessModal.vue'
import Doc from '@/assets/icons/doc-right.svg';
import { getCurrentClinic, getCurrentUser } from '@/utils/localStorageManager'
import { saveAs } from 'file-saver'

export default {
  components: {
    Doc,
    DocumentModal,
    ImportXmlTissErrorModal,
    ImportXmlTissSuccessModal    
  },
  props:{
    changeShowPatientDocument: Function
  },
  created(){
    this.getLastPatientHistory()
  },
  mounted() {
    this.channel.bind('patient_history_started', this.startedPatientHistory, this)
    this.channel.bind('finished_patient_history', this.finishPatientHistory, this)
    this.channel.bind('patient_history_failed', this.failedPatientHistory, this)
    
    this.channel.bind('onlending_extract_started', this.startedOnlendingExtract, this)
    this.channel.bind('finished_onlending_extract', this.finishOnlendingExtract, this)
    this.channel.bind('onlending_extract_failed', this.failedOnlendingExtract, this)

    this.channel.bind('tiss_batch_started', this.startedTissBatch, this)
    this.channel.bind('finished_tiss_batch', this.finishTissBatch, this)
    this.channel.bind('tiss_batch_failed', this.failedTissBatch, this)

    this.channel.bind('management_report_started', this.startedManagementReport, this)
    this.channel.bind('finished_management_report', this.finishManagementReport, this)
    this.channel.bind('management_report_failed', this.failedManagementReport, this)

    this.channel.bind('import_tiss_xml_success', this.finishImportTissXml, this)
    this.channel.bind('import_tiss_xml_error', this.finishImportTissXmlError, this)

    this.channel.bind('finished_bill_preferences_report', this.finishBillPreferencesReport, this)
    this.channel.bind('print_sus_batches', this.finishSusBatchCoverPageGenerated, this)

  },
  data() {
    return {
      clinic: getCurrentClinic(),
      user: getCurrentUser(),
      processing: false,
      finished: false,
      hasFailure: false,
      url: null,
      documentName: null,
      attendanceFileId: null,
      patientHistory: null,
      modal_xml_tiss_error: [],
      modal_xml_tiss: []
    }
  }, 
  computed: {
    ...mapGetters('pusher', ['channel']),
  },
  methods: {
    async openModal() {
      if (this.url) {
        await this.api.viewPatientHistory(this.patientHistory?.id);
        this.$bvModal.show('attached-document-modal')
        this.changeShowPatientDocument(false)
      }
    },
    startedPatientHistory(data) {
      if (this.user.id === data.professional_id) {
        this.attendanceFileId = null
        this.finished = false
        this.changeShowPatientDocument(true)
        this.processing = true
      }
    },
    async finishPatientHistory(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.changeShowPatientDocument(true)
        this.finished = true
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        data = JSON.parse(data.payload)
        this.getLastPatientHistory()
        if(data.attendance_file_id) this.attendanceFileId = data.attendance_file_id;
      }
    },
    async finishImportTissXmlError(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.finished = true
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        data = JSON.parse(data.payload)

        this.modal_xml_tiss_error = data.resume;
        this.$bvModal.show('import-xml-tiss-error');
      }
    },
    async finishImportTissXml(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.finished = true
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        data = JSON.parse(data.payload)     
        this.modal_xml_tiss = data.resume;
        this.$bvModal.show('import-xml-tiss-success');
      }
    },
    async failedPatientHistory() {
      this.$toast.error('Erro ao gerar histórico do paciente!')
      this.changeShowPatientDocument(false)
      this.processing = false
    },

    startedOnlendingExtract(data) {
      if (this.user.id === data.professional_id) {
        this.finished = false
        this.changeShowPatientDocument(true)
        this.processing = true
      }
    },
    async finishOnlendingExtract(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.changeShowPatientDocument(false)

        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        if(data.opened) return

        data = JSON.parse(data.payload)
        const pdf = atob(data.pdf)
        var bytes = new Uint8Array(pdf.length);
        for (var i = 0; i < pdf.length; i++) {
          bytes[i] = pdf.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' })
        this.$toast.success('Extrato de repasse gerado com sucesso!')
        saveAs(blob, data.documentName)
      }
    },
    async failedOnlendingExtract(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.$toast.error('Erro ao gerar extrato de repasse!')
      }
    },

    startedTissBatch(data) {
      if (this.user.id === data.professional_id) {
        this.finished = false
        this.changeShowPatientDocument(true)
        this.processing = true
      }
    },
    async finishTissBatch(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.changeShowPatientDocument(false)

        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        if(data.opened) return
        data = JSON.parse(data.payload)

        data.pdfs.map(item => {
          const pdfData = Uint8Array.from(window.atob(item.pdf), c =>
            c.charCodeAt(0)
          ).buffer
          const blob = new Blob([pdfData], { type: 'application/pdf' })
          saveAs(blob, `${item.name} - ${this.moment().format('DD-MM-YYYY')}`)
        })
        this.$toast.success('Folha(s) de rosto baixada(s) com sucesso!')
      }
    },
    async failedTissBatch(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.$toast.error('Erro ao gerar folha de rosto!')
      }
    },

    startedManagementReport(data) {
      if (this.user.id === data.professional_id) {
        this.finished = false
        this.changeShowPatientDocument(true)
        this.processing = true
      }
    },
    async finishManagementReport(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.changeShowPatientDocument(false)
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        if(data.opened) return
        data = JSON.parse(data.payload)

        const file = atob(data.file)
        var bytes = new Uint8Array(file.length);
        for (var i = 0; i < file.length; i++) {
          bytes[i] = file.charCodeAt(i);
        }

        saveAs(
						new Blob([bytes], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
						}),
						'relatorio.xlsx'
					);
        this.$toast.success('Download xlsx do relatório gerencial baixado com sucesso!')
      }
    },
    async failedManagementReport(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.$toast.error('Erro ao gerar download xlsx do relatório gerencial!')
      }
    },

    async getAttendanceFile() {
      const res = await this.api.getAttendanceFile(this.attendanceFileId);
      this.documentName = res.data.custom_name
      const doc = await this.api.getDocTemporaryUrl(res.data.path)
      this.url = doc.data;
    },
    async getLastPatientHistory() {
      const res = await this.api.getLastPatientHistory({
        professional_id: this.user.id, 
        clinic_id: this.clinic.id
      });
      if(res?.data?.id){
        this.patientHistory = res.data
        if(res.data?.status === 'finished'){     
          this.attendanceFileId = res?.data?.attendance_file_id ?? null
          if(this.attendanceFileId) this.changeShowPatientDocument(true)
        }
        else{
          this.processing = true
          this.changeShowPatientDocument(true)
        }
      }
    },

    async finishBillPreferencesReport(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.finished = true
        this.changeShowPatientDocument(false)
        let { data } = await this.api.getPusherEvent(pusherData.eventId)
        data = JSON.parse(data.payload)
        const pdf = atob(data.pdf)
        var bytes = new Uint8Array(pdf.length);
        for (var i = 0; i < pdf.length; i++) {
          bytes[i] = pdf.charCodeAt(i);
        }

        const blob = new Blob([bytes], { type: 'application/pdf' })
        saveAs(blob, data.documentName)

        this.$toast.success('PDF solicitado com sucesso! Em instantes você receberá o arquivo.')
      }
    },
    async finishSusBatchCoverPageGenerated(pusherData) {
      if (this.user.id === pusherData.identifier) {
        this.processing = false
        this.finished = true
        const { data } = await this.api.getPusherEvent(pusherData.eventId)
        const links = JSON.parse(data.payload)

        this.$toast.success('O processo de geração da folha de rosto SUS foi concluído com sucesso.')

        links.forEach(link => {
          window.open(link, '_blank');
        });
      }
    },
  },
  watch: {
    attendanceFileId: function (val) {
      if(val) this.getAttendanceFile()
    }
  }
}
</script>
<style lang="scss" scoped>
.attendance-file-process-wrapper {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.status {
  font-size: 1.5vh;
  .btn {
    font-size: 1.5vh;
  }
}
.loading {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}
.icon {
  width: 10%;
  height: 10%;
}

.doc {
  width: 50px;
  height: 50px;
  background-image: url('../../assets/images/doc_processing.gif');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100px;
}

.icon {
  width: 24px;
  height: 24px;
}
</style>
